<template>
  <div>
     <el-card>
      <search-form ref="searchForm" :topVisible="true" :tabData="tabData" labelWidth="120px" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
         <template #topBtn>
          <el-button type="primary" size="small" @click="handleClick('create')">{{ $t('text.add') }}</el-button>
          <el-button type="primary" size="small" :disabled="syncDisabled" @click="handleSync">{{ $t('form.zoo.sync') }}</el-button>
          <div class="synchronization-time" v-if="auto_ticket && auto_ticket.enable_auto_ticket">{{$t('text.lastSynchronizationTime')}}{{dateFormat(auto_ticket.last_synced_at)}}</div>
        </template>
      </search-form>
      <create-table @forwardToPage="forwardToPage" @handleNewTab="handleNewTab($event, 'zooTicketsDetail')" :operationColWidth="150" :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort" @onSwitchChange="handleCreateTableSwitchChange">
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
        </template>
        <template #rightBtn="scope">
          <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
          <el-button class="margintop10" type="primary" size="mini" @click="handleBaseClick(scope.row)">{{$t('form.zoo.manageTickets')}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <dialogEffect v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import { getTicketsList, getSetup, deleteZooTickets, toogleOnlineTicketPackages, getTicketSync } from '@/services/zoo-tickets'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import loading from '@/utils/loading.json'
import Utils from '@/utils/Utils'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'zooTicketsManager',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      auto_ticket: null,
      syncDisabled: false,
    }
  },
  watch: {
    $route (to, from) {
      // console.log(from)
      // if (from.name === 'managerUserOrderView') {
      //   this.$nextTick(() => {
      //     this.search()
      //     this.$refs.createTable.$refs.table.doLayout()
      //   })
      // }
    }
  },
  created () {
    this.queryData(getSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getSetup)
    this.isActive = true
  },
  methods: {
    handleBaseClick (item) {
      if (item.package_type.value === 'Bundle Ticket' || item.package_type.value === '套票') {
        this.$router.push({
          name: 'basicTicketPackageList',
          params: {
            ticket_package_id_eq: item.id.value
          }
        })
      } else if (item.package_type.value === 'Multi Park' || item.package_type.value === '多园票') {
        this.$router.push({
          name: 'basicMultiParkTicketsList',
          params: {
            ticket_package_id_eq: item.id.value
          }
        })
      } else {
        this.$router.push({
          name: 'basicTicketsTypeList',
          params: {
            ticket_package_id_eq: item.id.value
          }
        })
      }
    },
    forwardToPage (data) {
      if (data.model_name && data.model_name === 'site') {
        this.$router.push({
          name: 'attractionsDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'zooTicketsAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'zooTicketsDetail',
          params: {
            id: data.id.value,
            ticketType: data.package_type.value
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'zooTicketsAdd'
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'zooTicketsEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteZooTickets)
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getTicketsList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          this.auto_ticket = res.data.auto_ticket
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleCreateTableSwitchChange (data) {
      const _this = this
      const { rowData, jsonItemData } = data
      console.log('-----------', rowData[jsonItemData.prop].value)

      if (jsonItemData.prop === 'active') {
        _this.setGlobalLoading(true)
        const value = rowData[jsonItemData.prop].value ? 'online' : 'offline'
        toogleOnlineTicketPackages(rowData.id.value, value).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.search()
        }).catch(() => {
          _this.setGlobalLoading(false)
          _this.search()
        })
      }
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    async handleSync () {
      this.syncDisabled = true
      this.setGlobalLoading(true)
      await getTicketSync()
      this.$message({
        type: 'success',
        message: this.$t('message.synchronizationStarted')
      })
      this.setGlobalLoading(false)
    },
    dateFormat (date) {
      return Utils.dateFormat('YYYY-mm-dd HH:MM:SS', date)
    },
  }
}
</script>

<style>
.synchronization-time {
  font-size: 12px;
  line-height: 32px;
  padding-left: 12px;
}

</style>
